import Vue from 'vue'
import Router from 'vue-router'
import identity from './identity'
Vue.use(Router)

let routes = [
  {
    path: '',
    component: () => import('@/views/Layout'),
    children: [
      {
        name: '/',
        path: '/',
        component: () => import('@/views/home/index'),
      },
      {
        path: '/home',
        name: 'dashboard',
        component: () => import('@/views/home/index'),
      },
      {
        path: '/ibreport',
        name: 'ibReport',
        // component: () => import('@/views/IbReport'),
        component: () => import('@/views/ibReport/index'),
      },
      {
        path: '/rebatereport',
        name: 'rebateReport',
        // component: () => import('@/views/RebateReport'),
        component: () => import('@/views/rebateReport/index'),
      },
      {
        path: '/iblevel',
        name: 'iblevel',
        component: () => import('@/views/ibLevel/index'),
      },
      {
        path: '/accountManagement',
        name: 'accManagement',
        component: () => import('@/views/accountManage/index'),
      },
      // {
      //   path: '/pendingaccounts',
      //   name: 'accManagement',
      //   component: () => import('@/views/PendingAccounts'),
      // },
      // {
      //   path: '/leads',
      //   name: 'accManagement',
      //   component: () => import('@/views/Leads'),
      // },
      {
        path: '/rebateTransfer',
        name: 'rebateTransfer',
        component: () => import('@/views/RebateTransfer'),
      },
      {
        path: '/rebateWithdraw',
        name: 'rebateWithdraw',
        component: () => import('@/views/RebateWithdraw'),
      },
      {
        path: '/settings',
        name: 'ibProfile',
        component: () => import('@/views/Settings'),
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('@/views/ContactUs'),
      },
      {
        path: '/rebatePaymentHistory',
        name: 'payHistory',
        component: () => import('@/views/RebatePaymentHistory'),
      },
      {
        path: '/referralLinks',
        name: 'refLinks',
        component: () => import('@/views/ReferralLinks'),
      },
    ],
  },
]

const router = new Router({
  mode: 'history',
  routes: [
    ...routes,
    {
      path: '',
      component: () => import('@/views/Layout'),
      children: [
        {
          name: '404',
          path: '*',
          component: () => import('@/views/404'),
        },
      ],
    },
  ],
})

export default router
identity.authorize()
