const { WHITELIST } = require('@/constants/internationalBankTransfer')

module.exports = {
  info: {
    url: 'www.puprime.com',
    map:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4616.687864043498!2d55.45538001942663!3d-4.619136458242956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x22e028533cfa53ad%3A0x48d516d8499b9550!2sOrion%20Building!5e0!3m2!1sen!2sau!4v1592795342692!5m2!1sen!2sau',
    address: `<span>Suite C, Orion Mall, Palm Street, Victoria, Mahe, Seychelles</span>`,
    phone: '+248 4671 948',
    email: 'info@puprime.com',
    accountEmail: 'info@puprime.com',
    referralSite: 'www.puprime.com',
    blacklistEmail: 'op@puprime.com',
  },
  menus: [
    {
      name: 'menu.dashboard',
      path: '/home',
      icon: 'menu_icon_home',
    },
    {
      name: 'menu.ibReport',
      path: '/ibreport',
      icon: 'menu_icon_ib_report',
    },
    {
      name: 'menu.rebateReport',
      path: '/rebatereport',
      icon: 'menu_icon_rebate_report',
    },
    {
      name: 'menu.accManagement',
      path: '/accountManagement',
      icon: 'menu_icon_account',
      // children: [
      //   {
      //     name: 'menu.ibAccs',
      //     path: '/ibaccounts',
      //   },
      //   {
      //     name: 'menu.pendingAccs',
      //     path: '/pendingaccounts',
      //   },
      //   {
      //     name: 'menu.leads',
      //     path: '/leads',
      //   },
      // ],
    },
    {
      name: 'menu.payHistory',
      path: '/rebatePaymentHistory',
      icon: 'menu_icon_history',
    },
    {
      name: 'menu.iblevel',
      path: '/iblevel',
      icon: 'menu_icon_diagram',
    },
    {
      name: 'menu.refLinks',
      path: '/referralLinks',
      icon: 'menu_icon_links',
    },
    {
      name: 'menu.contactUs',
      path: '/contactUs',
      icon: 'menu_icon_contact',
    },
  ],
  withdrawMethods: {
    1: 'Credit/Debit Card',
    2: {
      1: 'Bank Transfer (Australia)',
      2: 'Bank Transfer (International)',
      3: 'Bank Transfer (China)',
    },
    3: 'Skrill / Neteller',
    4: 'Union Pay',
    5: 'Internet Banking (Thailand)',
    6: 'Internet Banking (Malaysia)',
    7: 'FasaPay',
    8: 'Internet Banking (Vietnam)',
    9: 'Internet Banking (Nigeria)',
    24: 'Internet Banking (India)',
    31: 'Skrill',
    32: 'Neteller',
    33: 'Paypal',
    34: 'Cryptocurrency-Bitcoin',
    35: 'Internet Banking (Korea)',
    36: 'Cryptocurrency-USDT',
    37: 'Bitwallet',
    38: 'SticPay',
    39: 'Internet Banking (Indonesia)',
    40: 'Internet Banking (Philippine)',
    41: 'astropay',
    42: 'Internet Banking (South Africa)',
    44: 'Vload',
    50: 'Internet Banking (Uganda)',
    51: 'Internet Banking (Rwanda)',
    52: 'Internet Banking (Zambia)',
    53: 'Internet Banking (Congo)',
    54: 'Internet Banking (Cameroon)',
    55: 'Internet Banking (Burundi)',
    56: 'Internet Banking (Kenya)',
    57: 'Internet Banking (Ghana)',
    58: 'Internet Banking (Tanzania)',
  },
  internationalBankTransfer: {
    WITHDRAW: {
      ALLOW_ALL: true,
      WHITELIST: WHITELIST,
    },
  },
}
